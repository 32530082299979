<template>
  <div class="markup-tables flex">
    <va-card :title="$t('tables.basic')" class="flex mb-4">
      <va-card-content>
        <div class="table-wrapper">
          <page-title :pageTitle="$t('message.update_message')"/>
          <property-form :disabled="loading" :submitAction="submitAction"/>
        </div>
      </va-card-content>
      <br />
      <va-card :title="$t('tables.basic')" class="flex mb-4">
        <data-tables />
      </va-card>
    </va-card>
  </div>
</template>

<script>
import {UPDATE_PROPERTY, GET_PROPERTY} from "@mdb/core/src";
import PropertyForm from './PropertyForm.vue'
import PageTitle from '@/components/text/PageTitle'
import { useQuery, useResult } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'

export default {
  components: { PropertyForm, PageTitle },
  setup (props) {
    const route = useRoute()
  
    const { result, loading, error } = useQuery(GET_PROPERTY, {
      id: route.params.id
    });

    const property = useResult(
      result,
      [],
      data => data.result
    );
    
    const updateProperty = useFormMutation(UPDATE_PROPERTY);

    const submitForm = (data) => {
      updateProperty.mutate({data: data});
    }

    const submitAction = () => {
      submitForm();
      success.value = true
    }

    return {
      loading: loading || updateProperty.loading,
      error,
      property : property,
      id: route.params.id,
      mutation: UPDATE_MESSAGE
    };
  },
};
</script>

<style lang="scss">
.markup-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
